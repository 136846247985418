import React from 'react';
import axios from 'axios';
import { BASH_URL, API_URL } from './../../services/index';
import {Helmet} from "react-helmet";

export default class EnquiryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            query_res: '',
            enquiry_submit: false
        }
        this.submitQuery = this.submitQuery.bind(this);
    }
    async submitQuery() {        
        if (this.props.data) {
            axios.post(API_URL + 'contact/query/create', {
                product: this.props.data,
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message,
            })
                .then(response => {
                    window.location.href = "/thanks";                    
                    this.setState({
                        query_res: 'success',
                        enquiry_submit: true
                    })
                })
                .catch(err => {
                    this.setState({
                        query_res: 'fail'
                    })
                })
        } else {
            axios.post(API_URL + 'contact/query/create', {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message,
            })
                .then(response => {
                    window.location.href = "/thanks"; 
                    this.setState({
                        query_res: 'success',
                        enquiry_submit: true
                    })
                })
                .catch(err => {
                    this.setState({
                        query_res: 'fail'
                    })
                })
        }
        this.external_script()
    }
    external_script(){
        const script = document.createElement("script");

        script.src = "./thankScript.js";
        script.async = true;

        document.body.appendChild(script);
    }

    render() {
        return (
            <div className="container-form">
                {/* {this.state.query_res == 'success' &&
                    <div style={{ color: 'green' }}>
                        <h4>Thanks for your response. For Catalog please visit your email.</h4>
                    </div>
                } */}
                {this.state.query_res == 'fail' &&
                    <div style={{ color: 'red' }}>
                        <h4>Sorry there are some problem please try again.</h4>
                    </div>
                }
                <form>
                    {this.state.enquiry_submit == false &&
                    <>
                    <h4 className="enq-heading">Product Enquiry</h4>
                    <hr />
                    <input type="text" placeholder="Name" onChange={(event) => this.setState({ name: event.target.value })} required autofocus />
                    {/* <input type="email" placeholder="Email" onChange={(event) => this.setState({ email: event.target.value })} required /> */}
                    <input type="tel" placeholder="Mobile Number" onChange={(event) => this.setState({ phone: event.target.value })} required />
                    <textarea placeholder="Message (optional)" onChange={(event) => this.setState({ message: event.target.value })} required></textarea>
                    <input type='button' onClick={this.submitQuery} value="Submit" className="EformSubmit" />
                    </>
                    }  
                    {this.state.enquiry_submit == true &&
                    <>                    
                    <div className='enquirySuccess'>
                        <p className='successIcon'><i className="far fa-check-circle"></i></p>
                        <p className='successText'>Thanks for Enquiry. We will get back to you shortly.</p>
                    </div>
                    </>
                    }                  
                </form>
            </div>
        )
    }
}
