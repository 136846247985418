import React from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import axios from 'axios';
import { BASH_URL, API_URL } from './../../services/index';
import MediaQuery from 'react-responsive';
import HeaderComponent from '../common/header.component';

export default class Dashboard extends React.Component {
    state = {
        orderlist: [],
        address: [],
        name: '',
        phone: '',
        email: '',
        gstn: '',
        streat_address: '',
        city: '',
        state: '',
    }

    constructor(props) {
        super(props);
        this.updateAccount = this.updateAccount.bind(this);
        this.logOut = this.logOut.bind(this);
    }

    async logOut() {
        localStorage.clear('token');
        localStorage.clear('userid');
        localStorage.clear('user_name');
        this.props.history.push('/')
    }


    async getOrderList() {
        axios.get(`${API_URL}/order/api/v1/invoice/${localStorage.getItem('userid')}`, {
            headers: {
                'Authorization': 'Token' + localStorage.getItem('token')
            }
        })
            .then(response => {
                console.log(response.data)
                this.setState({
                    'orderlist': response.data
                })
            })
            .catch(error => console.log(error))
    }

    async getAddress() {
        axios.get(`${API_URL}/order/api/v1/user/address/${localStorage.getItem('userid')}`, {
            headers: {
                'Authorization': 'Token' + localStorage.getItem('token')
            }
        })
            .then(response => {
                console.log(response.data)
                this.setState({
                    'address': response.data,
                    'name': response.data.name,
                    'phone': response.data.phone,
                    'email': response.data.email,
                    'streat_address': response.data.streat_address,
                    'city': response.data.city,
                    'state': response.data.state,
                    'gstn': response.data.gstn,
                })
            })
            .catch(error => console.log(error))
    }

    updateAccount() {
        axios.post(`${API_URL}/order/api/v1/user/address/update`, {
            'user': localStorage.getItem('userid'),
            'phone': this.state.phone,
            'city': this.state.city,
            'email': this.state.email,
            'name': this.state.name,
            'gstn': this.state.gstn,
            'state': this.state.state,
            'streat_address': this.state.streat_address,
        })
            .then(response => {
                console.log(response.data)
                this.setState({
                    'address': response.data
                })
                alert('Profile Update succesfully.')
            })
            .catch(error => console.log(error))
    }

    componentDidMount() {
        this.getOrderList()
        this.getAddress()
    }
    render() {
        return (
            <React.Fragment>
                <MediaQuery maxWidth={1224}>
                    <Header />
                </MediaQuery>
                <MediaQuery minWidth={1224}>
                    <HeaderComponent />
                </MediaQuery>
                <div>
                    {/* My Account Start */}
                    <div className="my-account">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                                        {/* <a className="nav-link active" id="dashboard-nav" data-toggle="pill" href="#dashboard-tab" role="tab"><i className="fa fa-tachometer-alt" />Dashboard</a> */}
                                        <a className="nav-link" id="orders-nav" data-toggle="pill" href="#orders-tab" role="tab"><i className="fa fa-shopping-bag" />My Orders</a>
                                        {/* <a className="nav-link" id="payment-nav" data-toggle="pill" href="#payment-tab" role="tab"><i className="fa fa-credit-card" />Payment Method</a> */}
                                        <a className="nav-link" id="address-nav" data-toggle="pill" href="#address-tab" role="tab"><i className="fa fa-map-marker-alt" />address</a>
                                        <a className="nav-link" id="account-nav" data-toggle="pill" href="#account-tab" role="tab"><i className="fa fa-user" />Account Details</a>
                                        <a className="nav-link" onClick={this.logOut}><i className="fa fa-sign-out-alt" />Logout</a>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="tab-content">
                                        {/* <div className="tab-pane fade show active" id="dashboard-tab" role="tabpanel" aria-labelledby="dashboard-nav">
                                            <h4>Dashboard</h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In condimentum quam ac mi viverra dictum. In efficitur ipsum diam, at dignissim lorem tempor in. Vivamus tempor hendrerit finibus. Nulla tristique viverra nisl, sit amet bibendum ante suscipit non. Praesent in faucibus tellus, sed gravida lacus. Vivamus eu diam eros. Aliquam et sapien eget arcu rhoncus scelerisque.
              </p>
                                        </div> */}
                                        <div className="tab-pane fade show active" id="orders-tab" role="tabpanel" aria-labelledby="orders-nav">
                                            <div className="table-responsive">
                                                <table className="table table-bordered">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th>Product</th>
                                                            <th>Date</th>
                                                            <th>Payment Status</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.orderlist && this.state.orderlist.map(item => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {item.product_detail && item.product_detail.map(product => {
                                                                            return (
                                                                                <div>
                                                                                    <p>{product.product_name} | {product.product_description}, </p>
                                                                                    <hr />
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </td>
                                                                    <td>{item.delivery_date}</td>
                                                                    <td>
                                                                        {item.payment_status == 'unpaid' &&
                                                                            <p>Due</p>
                                                                        }
                                                                        {item.payment_status == 'paid' &&
                                                                            <p>Paid</p>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {item.product_detail && item.product_detail.map(product => {
                                                                            return (
                                                                                <div>
                                                                                    <p>{product.quantity} peace , </p><hr />
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </td>
                                                                    <td><button className="btn" onClick={() => window.open(`${API_URL}/order/api/v1/invoice/print/${item.id}`, "_blank")}>View Invoice</button></td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="payment-tab" role="tabpanel" aria-labelledby="payment-nav">
                                            <h4>Payment Method</h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In condimentum quam ac mi viverra dictum. In efficitur ipsum diam, at dignissim lorem tempor in. Vivamus tempor hendrerit finibus. Nulla tristique viverra nisl, sit amet bibendum ante suscipit non. Praesent in faucibus tellus, sed gravida lacus. Vivamus eu diam eros. Aliquam et sapien eget arcu rhoncus scelerisque.
              </p>
                                        </div>
                                        <div className="tab-pane fade" id="address-tab" role="tabpanel" aria-labelledby="address-nav">
                                            <h4>Address</h4>
                                            <div className="row">
                                                {/* <div className="col-md-6">
                                                    <h5>Payment Address</h5>
                                                    <p>123 Payment Street, Los Angeles, CA</p>
                                                    <p>Mobile: 012-345-6789</p>
                                                    <button className="btn">Edit Address</button>
                                                </div> */}
                                                <div className="col-md-6">
                                                    <h5>Shipping Address</h5>
                                                    <p>{this.state.address.streat_detail}</p>
                                                    <p>{this.state.address.city}, {this.state.address.state}</p>
                                                    <p>Mobile: {this.state.address.phone}</p>
                                                    {/* <button className="btn">Edit Address</button> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="account-tab" role="tabpanel" aria-labelledby="account-nav">
                                            <h4>Account Details</h4>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input className="form-control" value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} type="text" placeholder="Name" />
                                                </div>
                                                <div className="col-md-6">
                                                    <input className="form-control" value={this.state.phone} onChange={(event) => this.setState({ phone: event.target.value })} type="text" placeholder="Mobile" />
                                                </div>
                                                <div className="col-md-6">
                                                    <input className="form-control" value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} type="text" placeholder="Email" />
                                                </div>
                                                <div className="col-md-6">
                                                    <input className="form-control" value={this.state.gstn} onChange={(event) => this.setState({ gstn: event.target.value })} type="text" placeholder="GSTN" />
                                                </div>
                                                <div className="col-md-12">
                                                    <input className="form-control" value={this.state.streat_address} onChange={(event) => this.setState({ streat_address: event.target.value })} type="text" placeholder="Streat Address" />
                                                </div>
                                                <div className="col-md-6">
                                                    <input className="form-control" value={this.state.city} onChange={(event) => this.setState({ city: event.target.value })} type="text" placeholder="City" />
                                                </div>
                                                <div className="col-md-6">
                                                    <input className="form-control" value={this.state.state} onChange={(event) => this.setState({ state: event.target.value })} type="text" placeholder="State" />
                                                </div>
                                                <div className="col-md-12">
                                                    <button className="btn" onClick={this.updateAccount}>Update Account</button>
                                                    <br /><br />
                                                </div>
                                            </div>
                                            {/* <h4>Password change</h4>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <input className="form-control" type="password" placeholder="Current Password" />
                                                </div>
                                                <div className="col-md-6">
                                                    <input className="form-control" type="text" placeholder="New Password" />
                                                </div>
                                                <div className="col-md-6">
                                                    <input className="form-control" type="text" placeholder="Confirm Password" />
                                                </div>
                                                <div className="col-md-12">
                                                    <button className="btn">Save Changes</button>
                                                </div>
                                            </div>
                                         */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* My Account End */}
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}