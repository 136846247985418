import React from 'react';
import Slider from "react-slick";
import axios from "axios";
import { BASH_URL, API_URL } from './../../services/index';
// import Banner1 from './../../assest/img/banner/banner-slider-1.webp';
// import Banner2 from './../../assest/img/banner/banner-slider-2.webp';
// import Banner3 from './../../assest/img/banner/banner-slider-3.webp';
import Banner1 from './../../assest/img/banner/lb1.jpg';
import Banner2 from './../../assest/img/banner/lb2.jpg';
import Banner3 from './../../assest/img/banner/lb3.jpg';
import { Modal } from 'react-responsive-modal';
import EnquiryForm from './../common/enquiryform';

export default class Banner extends React.Component {
    state = {
        'list_banners': [],
        open: false,
    }
    async getBanner() {
        axios.get(API_URL + 'home/banner/top')
            .then(response => {
                this.setState({
                    'list_banners': response.data
                })
            })
            .catch(error => console.log(error))
    }

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onOpenModal(product) {
        this.setState({
            open: false,
        });
    };

    componentDidMount() {
        // this.getBanner();
    }
    render() {
        const { open } = this.state;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000

        }
        return (
            <div>

                <Slider {...settings}>
                    <div className="header-slider-item" onClick={() => this.setState({ open: true })}>
                        <img src={Banner1}  alt="Slider Image" className='slider-image' />
                    </div>
                    <div className="header-slider-item" onClick={() => this.setState({ open: true })}>
                        <img src={Banner2} alt="Slider Image" className='slider-image' />
                    </div>
                    <div className="header-slider-item" onClick={() => this.setState({ open: true })}>
                        <img src={Banner3} alt="Slider Image" className='slider-image' />
                    </div>
                    {/* {this.state.list_banners.map(banner => {
                        return (
                            <div className="header-slider-item">
                                <img src={`${BASH_URL}` + banner.slider_banner} alt="Slider Image" className='slider-image' />
                            </div>
                        )
                    })} */}

                </Slider>
                <div className='header-search-main'>
                    <Modal className='enquiry-model' open={open} onClose={this.onCloseModal}>
                        <EnquiryForm />
                    </Modal>
                </div>
            </div>
        );
    }
}