// export const BASH_URL = "http://45.79.126.17"
// export const API_URL = "http://45.79.126.17/api/v1/"

export const BASH_URL = "https://li2147-17.members.linode.com"
export const API_URL = "https://li2147-17.members.linode.com/api/v1/"


// Local Settings
// export const BASH_URL = "http://localhost:8080"
// export const API_URL = "http://localhost:8080/api/v1/"
