import React from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import Slider from "react-slick";
import { Modal } from 'react-responsive-modal';
import EnquiryForm from '../common/enquiryform';
import ReactStars from "react-rating-stars-component";
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import MetaTags from 'react-meta-tags';
import { BASH_URL, API_URL } from './../../services/index';
import ProductCard from '../common/product-card';
import MediaQuery from 'react-responsive';
import HeaderComponent from '../common/header.component';
import MobileHeader from '../common/headermobile.component';
import './../../assest/css/product.css';

export default class ProductDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product_detail: [],
            category: [],
            open: false,
            product_enquery: '',
            product_review: '',
            star_count: '',
            name: '',
            email: '',
            review: '',
            related_product: '',
            slide: [],
            settings: [],
            pricefilter: [],
        }
        this.ratingChanged = this.ratingChanged.bind(this);
        this.giveRating = this.giveRating.bind(this);
    }

    async giveRating() {
        axios.post(`${API_URL}contact/review/create`, {
            product: this.state.product_detail.id,
            star_count: this.state.star_count,
            name: this.state.name,
            email: this.state.email,
            review: this.state.review,
        })
            .then(response => {
                this.getReview(this.state.product_detail.id)
            })
            .catch(err => console.log(err))
    }

    async getRelatedProduct() {
        axios.get(`${API_URL}product/related/${this.props.match.params.slug}`)
            .then(response => {
                this.setState({
                    'related_product': response.data
                })
            })
            .catch(error => console.log(error))
    }

    async getSlide() {
        axios.get(`${API_URL}home/image/card`)
            .then(response => {
                this.setState({
                    'slide': response.data
                })
            })
            .catch(error => console.log(error))
    }

    ratingChanged(newRating) {
        this.setState({
            star_count: newRating
        })
    };

    onOpenModal(product) {
        console.log(product.id)
        this.setState({
            open: true,
            product_enquery: product.id
        });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    async getProductDetail() {
        axios.get(`${API_URL}deatil/product/${this.props.match.params.slug}`)
            .then(response => {
                this.setState({
                    'product_detail': response.data
                })
                this.getReview(response.data.id)
            })
            .catch(error => console.log(error))
    }

    async getCategory() {
        axios.get(`${API_URL}home/header/category`)
            .then(response => {
                this.setState({
                    'category': response.data[0].sub_category,
                    'processor': response.data[2].sub_category
                })
            })
            .catch(error => console.log(error))
    }

    async getReview(id) {
        axios.get(`${API_URL}contact/review/${id}`)
            .then(response => {
                this.setState({
                    'product_review': response.data
                })
            })
            .catch(error => console.log(error))
    }

    async getSetting() {
        axios.get(`${API_URL}app/settings`)
            .then(response => {
                console.log(response.data[0], 'setting')
                this.setState({
                    'settings': response.data[0]
                })
                localStorage.setItem('phone_number', response.data[0].contact_phone)
            })
            .catch(error => console.log(error))
    }

    async getPricefilter() {
        axios.get(`${API_URL}filter/price`)
            .then(response => {
                this.setState({
                    'pricefilter': response.data
                })
            })
            .catch(error => console.log(error))
    }

    async loadModel(){
        setTimeout(() => {     
            this.setState({
                open: true
            })
          }, 5000);
        
    }

    componentDidMount() {
        this.getProductDetail();
        this.getCategory();
        this.getRelatedProduct();
        this.getSlide()
        // this.getSetting()
        this.getPricefilter()
        this.loadModel()
    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            // autoplay: true,
            autoplaySpeed: 2000
        };

        const { open } = this.state;
        return (
            <div>
                <MetaTags>
                    <title>{this.state.product_detail.meta_title}</title>
                    <meta name="description" content={`${this.state.product_detail.meta_keywords}`} />
                </MetaTags>
                <MediaQuery maxWidth={1224}>
                    <MobileHeader />
                </MediaQuery>
                <MediaQuery minWidth={1224}>
                    <HeaderComponent />
                </MediaQuery>
                {/* Product Detail Start */}
                <div className="product-detail">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="product-detail-top">
                                    <div className="row align-items-center">
                                        <div className="col-md-5">
                                            <div className="product-slider-single normal-slider">
                                                <Slider {...settings}>
                                                    <div>
                                                        <InnerImageZoom src={`${BASH_URL}` + this.state.product_detail.thumbnail_image} zoomSrc={`${BASH_URL}` + this.state.product_detail.thumbnail_image} zoomType='hover' sizes='400*400' />
                                                        {/* <img src={`${BASH_URL}` + this.state.product_detail.thumbnail_image} alt="Product Image" /> */}
                                                    </div>
                                                    <div>
                                                        <img src={`${BASH_URL}` + this.state.product_detail.small_image} alt="Product Image" />
                                                    </div>
                                                    <div>
                                                        <img src={`${BASH_URL}` + this.state.product_detail.full_image} alt="Product Image" />
                                                    </div>
                                                    <div>
                                                        <img src={`${BASH_URL}` + this.state.product_detail.scrach_image} alt="Product Image" />
                                                    </div>
                                                </Slider>

                                            </div>
                                            <div className="product-slider-single-nav normal-slider">
                                                {/* <Slider {...settings}> */}
                                                <div className="slider-nav-img product-detail-view"><img src={`${BASH_URL}` + this.state.product_detail.thumbnail_image} alt="Product Image" /></div>
                                                <div className="slider-nav-img product-detail-view"><img src={`${BASH_URL}` + this.state.product_detail.small_image} alt="Product Image" /></div>
                                                <div className="slider-nav-img product-detail-view"><img src={`${BASH_URL}` + this.state.product_detail.full_image} alt="Product Image" /></div>
                                                <div className="slider-nav-img product-detail-view"><img src={`${BASH_URL}` + this.state.product_detail.scrach_image} alt="Product Image" /></div>
                                                {/* </Slider> */}
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="product-content">
                                                <div className="title"><h1>{this.state.product_detail.product_name}</h1></div>
                                                <hr />
                                                <div className="price">
                                                    <p>₹ {this.state.product_detail.product_price_offer} <span>₹ {this.state.product_detail.product_price_actual}</span></p>
                                                </div>
                                                {/* <div className="quantity">
                                                    <p>🔥  Hurry, Only {this.state.product_detail.product_quantity} left!</p>
                                                </div> */}
                                                <hr />
                                                <div className='productSpec'>
                                                    <p className="productSpec-desc">
                                                        {ReactHtmlParser(this.state.product_detail.product_description)}
                                                    </p>
                                                </div>
                                                <div className="action">
                                                    <a className="btn productDetail-btn" onClick={() => this.onOpenModal(this.state.product_detail)}>Enquire Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row product-detail-bottom">
                                    <div className="col-lg-12">
                                        <ul className="nav nav-pills nav-justified mob-nav-desc">
                                        <li className="nav-item product-detail-nav">
                                                <a className="nav-link active" data-toggle="pill" href="#specification">Specification</a>
                                            </li>
                                            <li className="nav-item product-detail-nav">
                                                <a className="nav-link" data-toggle="pill" href="#description">Description</a>
                                            </li>                                           
                                            <li className="nav-item product-detail-nav">
                                                <a className="nav-link" data-toggle="pill" href="#reviews">Reviews ({this.state.product_review.length})</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                        <div id="specification" className="container tab-pane active">
                                                <h4 className="specification-head">Product specification</h4>
                                                <hr />
                                                <p className="specification-p">{ReactHtmlParser(this.state.product_detail.product_spacification)}</p>                                                
                                            </div>
                                            <div id="description" className="container tab-pane fade">
                                                <h4 className="specification-head">Product description</h4>
                                                <hr />
                                                <p>
                                                    {ReactHtmlParser(this.state.product_detail.product_description)}
                                                </p>
                                            </div>                                            
                                            <div id="reviews" className="container tab-pane fade">
                                                {this.state.product_review && this.state.product_review.slice(0, 3).map(review => {
                                                    return (
                                                        <div className="reviews-submitted">
                                                            <div className="reviewer">{review.name} - <span>{review.created_date}</span></div>
                                                            <div className="ratting">
                                                                <ReactStars
                                                                    count={5}
                                                                    value={review.star_count}
                                                                    size={24}
                                                                    activeColor="#ffd700"
                                                                />
                                                            </div>
                                                            <p>{review.review}</p>
                                                        </div>

                                                    )
                                                })}
                                                <div className="reviews-submit">
                                                    <h4>Give your Review:</h4>
                                                    <div className="ratting">
                                                        <ReactStars
                                                            count={5}
                                                            onChange={this.ratingChanged}
                                                            size={24}
                                                            activeColor="#ffd700"
                                                        />
                                                    </div>
                                                    <div className="row form">
                                                        <div className="col-sm-6">
                                                            <input type="text" placeholder="Name" onChange={(event) => this.setState({ name: event.target.value })} />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <input type="email" placeholder="Email" onChange={(event) => this.setState({ email: event.target.value })} />
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <textarea placeholder="Review" defaultValue={""} onChange={(event) => this.setState({ review: event.target.value })} />
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <button onClick={this.giveRating}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Side Bar Start */}
                            <MediaQuery minWidth={1224}>
                                <div className="col-lg-4s sidebar">
                                    <MediaQuery minWidth={1224}>
                                        <div className="sidebar-widget category">
                                            <h2 className="title">CATEGORY</h2>
                                            <hr />
                                            <nav className="navbar bg-light">
                                                <ul className="navbar-nav">
                                                    {this.state.category && this.state.category.map(category => {
                                                        return (
                                                            <li className="nav-item">
                                                                <a className="nav-link" href={`/category/${category.slug}`}><i class="fas fa-laptop"></i>{category.category_names}</a>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </nav>
                                        </div>
                                    </MediaQuery>
                                    <div className="sidebar-widget brands">
                                        <MediaQuery minWidth={1224}>
                                            <h2 className="title">PRICE RANGE</h2>
                                            <hr />
                                        </MediaQuery>
                                        <MediaQuery maxWidth={724}>
                                            <div style={{ marginTop: '-30px' }}>
                                                <h3>Price Range-:</h3>
                                                <hr />
                                            </div>
                                        </MediaQuery>
                                        <ul className='mobile-filter'>
                                            {this.state.pricefilter && this.state.pricefilter.map(filter => {
                                                return (
                                                    <a onClick={() => this.Productfilterprice(filter)} className="price-filter">Laptop Under <b>{filter.price_maximum} ₹</b>
                                                    </a>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    <div className="sidebar-widget brands">
                                        <MediaQuery minWidth={1224}>
                                            <h2 className="title">PROCESSOR</h2>
                                            <hr />
                                            <ul className='mobile-filter'>
                                                {this.state.processor && this.state.processor.map(filter => {
                                                    return (
                                                        <a className="price-filter" href={'/category/' + filter.slug}><i class="fas fa-microchip"></i> {filter.category_names}</a>
                                                    )
                                                })}
                                            </ul>
                                        </MediaQuery>

                                    </div>

                                </div>
                            </MediaQuery>
                            {/* Side Bar End */}
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="product">
                                    <div className="section-header">
                                        <h2>Tranding Products</h2>
                                    </div>
                                    <div className="row align-items-center product-slider product-slider-3">
                                        {this.state.related_product && this.state.related_product.slice(0, 6).map(product =>
                                            <div className="col-lg-3">
                                                <ProductCard product={product} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <div className='header-search-main'>
                    <Modal className='enquiry-model' open={open} onClose={this.onCloseModal}>
                        <EnquiryForm data={this.state.product_enquery} />
                    </Modal>
                </div>
            </div>

        )
    }
}