import React from 'react';
import axios from 'axios';
import Header from '../common/header';
import Footer from '../common/footer';
import { Modal } from 'react-responsive-modal';
import EnquiryForm from '../common/enquiryform';
import MetaTags from 'react-meta-tags';
import ProductCard from '../common/product-card';
import { BASH_URL, API_URL } from './../../services/index';
import MediaQuery from 'react-responsive';
import HeaderComponent from '../common/header.component';
import MobileHeader from '../common/headermobile.component';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';

export default class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'products': [],
            'filter_content': 'Featured',
            'category': [],
            'current_category': [],
            open: false,
            processor: [],
            loader: true
        }
    }

    onOpenModal(product) {
        this.setState({
            open: true,
            product_enquery: product.id
        });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    async setBrand(event) {
        this.setState({
            loader: true
        })
        axios.get(`${API_URL}category/product/${event.target.value}`)
            .then(response => {
                this.setState({
                    'products': response.data,
                    'filter_content': 'Featured',
                    loader: false
                })
            })
            .catch(error => console.log(error))
    }

    async setProcessor(event) {
        this.setState({
            loader: true
        })
        axios.get(`${API_URL}category/product/${event.target.value}`)
            .then(response => {
                this.setState({
                    'products': response.data,
                    loader: false
                })
            })
            .catch(error => console.log(error))
    }

    async setPriceFilter(event) {
        this.setState({
            loader: true
        })
        axios.post(`${API_URL}filter/price/product`, {
            'price_minimum': 0,
            'price_maximum': event.target.value,
        })
            .then(response => {
                console.log(response.data)
                this.setState({
                    'products': response.data,
                    loader: false
                })
            })
            .catch(error => console.log(error))
    }

    async getCategoryProduct() {
        this.setState({
            loader: true
        })
        axios.get(`${API_URL}category/product/${this.props.match.params.slug}`)
            .then(response => {
                this.setState({
                    'products': response.data,
                    'filter_content': 'Featured',
                    loader: false
                })
            })
            .catch(error => console.log(error))
    }

    async getCategoryDetail() {
        axios.get(`${API_URL}category/current/${this.props.match.params.slug}`)
            .then(response => {
                this.setState({
                    current_category: response.data
                })
            })
            .catch(error => console.log(error))
    }

    async getnewCategoryProduct() {
        this.setState({
            loader: true
        })
        axios.get(`${API_URL}category/product/new/${this.props.match.params.slug}`)
            .then(response => {
                this.setState({
                    'products': response.data,
                    'filter_content': 'Newest Arrival',
                    loader: false
                })
            })
            .catch(error => console.log(error))
    }

    async getlthCategoryProduct() {
        this.setState({
            loader: true
        })
        axios.get(`${API_URL}category/product/plth/${this.props.match.params.slug}`)
            .then(response => {
                this.setState({
                    'products': response.data,
                    'filter_content': 'Price: Low to High',
                    loader: false
                })
            })
            .catch(error => console.log(error))
    }

    async gethtlCategoryProduct() {
        this.setState({
            loader: true
        })
        axios.get(`${API_URL}category/product/phtl/${this.props.match.params.slug}`)
            .then(response => {
                this.setState({
                    'products': response.data,
                    'filter_content': 'Price: High to Low',
                    loader: false
                })
            })
            .catch(error => console.log(error))
    }

    async getCategory() {
        axios.get(`${API_URL}home/header/category`)
            .then(response => {
                this.setState({
                    'category': response.data[0].sub_category,
                    'processor': response.data[2].sub_category
                })
            })
            .catch(error => console.log(error))
    }

    async getPricefilter() {
        axios.get(`${API_URL}filter/price`)
            .then(response => {
                this.setState({
                    'pricefilter': response.data
                })
            })
            .catch(error => console.log(error))
    }

    async Productfilterprice(filter) {
        axios.post(`${API_URL}filter/price/product`, {
            'price_minimum': filter.price_minimum,
            'price_maximum': filter.price_maximum,
        })
            .then(response => {
                console.log(response.data)
                this.setState({
                    'products': response.data
                })
            })
            .catch(error => console.log(error))
    }

    async loadModel(){
        setTimeout(() => {     
            this.setState({
                open: true
            })
          }, 5000);
        
    }

    componentDidMount() {
        this.getCategoryProduct()
        this.getCategory()
        this.getPricefilter()
        this.getCategoryDetail()
        this.loadModel()
    }
    render() {
        const { open } = this.state;
        return (
            <div>
                <MetaTags>
                    <title>{this.state.current_category.meta_title}</title>
                    <meta name="description" content={`${this.state.current_category.meta_keywords}`} />
                    <meta property="og:title" content="MyApp" />
                </MetaTags>
                <MediaQuery maxWidth={1224}>
                    <MobileHeader />
                </MediaQuery>
                <MediaQuery minWidth={1224}>
                    <HeaderComponent />
                </MediaQuery>

                {/* Product List Start */}
                <div className="product-view">
                    <div className="container-fluid">
                        <div className="row">
                            {/* Side Bar Start */}
                            <MediaQuery minWidth={1224}>
                                <div className="col-lg-3 sidebar">
                                    <div className="sidebar-widget category">
                                        <h2 className="title">CATEGORY</h2>
                                        <hr />
                                        <nav className="navbar bg-light">
                                            <ul className="navbar-nav">
                                                {this.state.category && this.state.category.map(category => {
                                                    return (
                                                        <li className="nav-item">
                                                            <Link className="nav-link" to={{ pathname: `/category/${category.slug}` }}><i class="fas fa-laptop"></i>{category.category_names}</Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="sidebar-widget brands">
                                        <h2 className="title">PRICE RANGE</h2>
                                        <hr />
                                        <ul className='mobile-filter'>
                                            {this.state.pricefilter && this.state.pricefilter.map(filter => {
                                                return (
                                                    <a onClick={() => this.Productfilterprice(filter)} className="price-filter">Laptop Under <b>{filter.price_maximum} ₹</b>
                                                    </a>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    <div className="sidebar-widget brands">
                                        <h2 className="title">PROCESSOR</h2>
                                        <hr />
                                        <ul className='mobile-filter'>
                                            {this.state.processor && this.state.processor.map(filter => {
                                                return (
                                                    <a className="price-filter" href={'/category/' + filter.slug}><i class="fas fa-microchip"></i> {filter.category_names}</a>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </MediaQuery>
                            {/* Side Bar End */}
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-md-12">
                                        <MediaQuery maxWidth={924}>
                                            <div className='filter-sidebar'>
                                                <div className='scroll-side'>
                                                    <p>
                                                        <select onChange={(event) => this.setBrand(event)}>
                                                            <option value={this.props.match.params.slug}>BRAND</option>
                                                            {this.state.category && this.state.category.map(category => {
                                                                return (
                                                                    <option value={category.slug}>{category.category_names}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        <select onChange={(event) => this.setPriceFilter(event)}>
                                                            <option value="volvo">Price Range</option>
                                                            {this.state.pricefilter && this.state.pricefilter.map(filter => {
                                                                return (
                                                                    <option value={filter.price_maximum}>Laptop Under {filter.price_maximum} ₹</option>
                                                                )
                                                            })}
                                                        </select>
                                                        <select onChange={(event) => this.setProcessor(event)}>
                                                            <option value="volvo">Processor</option>
                                                            {this.state.processor && this.state.processor.map(filter => {
                                                                return (
                                                                    <option value={filter.slug}>{filter.category_names}</option>
                                                                )
                                                            }
                                                            )}
                                                        </select>

                                                    </p>
                                                    <hr />
                                                </div>
                                            </div>
                                        </MediaQuery>
                                        <div className="product-view-top">
                                            <div className="row">
                                                <div className="col-md-8">

                                                </div>
                                                <div className="col-md-4">
                                                    <div className="product-short">
                                                        <div className="dropdown">
                                                            <div className="dropdown-toggle" data-toggle="dropdown">Short: {this.state.filter_content}</div>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <a href="#" className="dropdown-item" onClick={() => this.getCategoryProduct()}>Featured</a>
                                                                <a href="#" className="dropdown-item" onClick={() => this.getnewCategoryProduct()}>Newest Arrival</a>
                                                                <a href="#" className="dropdown-item" onClick={() => this.getlthCategoryProduct()}>Price: Low to High</a>
                                                                <a href="#" className="dropdown-item" onClick={() => this.gethtlCategoryProduct()}>Price: High to Low</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.loader &&
                                        <div style={{ textAlign: 'center', marginLeft: '40%' }}>
                                            <Loader
                                                type="Puff"
                                                color="orange"
                                                height={60}
                                                width={60}
                                                timeout={30000} //3 secs
                                            />
                                        </div>
                                    }

                                    {!this.state.loader &&
                                        <>
                                            {this.state.products && this.state.products.map(product => {
                                                return (
                                                    <div className="col-lg-4">
                                                        <ProductCard product={product} />
                                                    </div>
                                                )
                                            })}
                                        </>
                                    }
                                </div>
                                <div className='row refurb-content homefooter-content category-page-content'>
                                    {ReactHtmlParser(this.state.current_category && this.state.current_category.description)}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
                <div className='header-search-main'>
                    <Modal className='enquiry-model' open={open} onClose={this.onCloseModal}>
                        <EnquiryForm data={this.state.product_enquery} />
                    </Modal>
                </div>
            </div>

        )
    }
}