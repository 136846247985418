import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import Header from '../common/header';
import Footer from '../common/footer';
import { BASH_URL, API_URL } from './../../services/index';
import MediaQuery from 'react-responsive';
import HeaderComponent from '../common/header.component';
import MobileHeader from '../common/headermobile.component';

export default class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'username': '',
            'password': '',
            'email': '',
            'first_name': '',
            'last_name': ''
        }
    }
    async login() {
        axios.post(`${API_URL}user/login`, {
            'username': this.state.username,
            'password': this.state.password
        })
            .then(response => {
                console.log(response);
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('user_name', response.data.user.username)
                localStorage.setItem('userid', response.data.user.id)
                this.props.history.push('/')
            })
            .catch(error => console.log(error))
    }

    async register() {
        if (this.state.cpassword == this.state.password) {
            axios.post(`${API_URL}user/register`, {
                'username': this.state.username,
                'password': this.state.password,
                'cpassword': this.state.cpassword,
                'first_name': this.state.first_name,
                'last_name': this.state.last_name,
                'email': this.state.email,
            })
                .then(response => {
                    console.log(response);
                    localStorage.setItem('token', response.data.token)
                    localStorage.setItem('user_name', response.data.user.username)
                    localStorage.setItem('userid', response.data.user.id)
                    this.props.history.push('/')
                })
                .catch(error => console.log(error))
        } else {
            alert('Your Password is not equal to confirm password.')
        }
    }
    render() {
        return (
            <div>
                <MediaQuery maxWidth={1224}>
                    <MobileHeader />
                </MediaQuery>
                <MediaQuery minWidth={1224}>
                    <HeaderComponent />
                </MediaQuery>
                <div>

                    {/* Login Start */}
                    <div className="login">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="register-form">
                                        <h4>Sign Up</h4>
                                        <hr />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>First Name</label>
                                                <input className="form-control" type="text" onChange={(event) => this.setState({ 'first_name': event.target.value })} placeholder="First Name" />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Last Name"</label>
                                                <input className="form-control" type="text" onChange={(event) => this.setState({ 'last_name': event.target.value })} placeholder="Last Name" />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Username</label>
                                                <input className="form-control" onChange={(event) => this.setState({ 'username': event.target.value })} type="text" placeholder="Username" />
                                            </div>
                                            <div className="col-md-6">
                                                <label>E-mail</label>
                                                <input className="form-control" type="text" onChange={(event) => this.setState({ 'email': event.target.value })} placeholder="E-mail" />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Password</label>
                                                <input className="form-control" type="password" onChange={(event) => this.setState({ 'password': event.target.value })} placeholder="Password" />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Confirm Password</label>
                                                <input className="form-control" onChange={(event) => this.setState({ 'cpassword': event.target.value })} type="text" placeholder="Confirm Password" />
                                            </div>
                                            <div className="col-md-12">
                                                <button className="btn" onClick={() => this.register()}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">                                    
                                    <div className="login-form">
                                    <h4>Sign In</h4>
                                    <hr />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Username</label>
                                                <input className="form-control" onChange={(event) => this.setState({ 'username': event.target.value })} type="text" placeholder="Username" />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Password</label>
                                                <input className="form-control" onChange={(event) => this.setState({ 'password': event.target.value })} type="text" placeholder="Password" />
                                            </div>
                                            <div className="col-md-12">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="newaccount" />
                                                    <label className="custom-control-label" htmlFor="newaccount">Keep me signed in</label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <button className="btn" onClick={() => this.login()}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Login End */}
                </div>

                <Footer />
            </div>
        )
    }
}