import React from "react";

export default class SSL extends React.Component {
    render() {
        return (
            <>
                3069C8D07B90C2362D0FBB6B664ED3B2702B8136A460B6CC1AB6901C9A5308E2
                comodoca.com
                15b40d0fd0e59f7
            </>
        )
    }
}