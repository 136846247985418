import React from 'react';
import axios from 'axios';
import Header from '../common/header';
import Footer from '../common/footer';
import { Modal } from 'react-responsive-modal';
import EnquiryForm from '../common/enquiryform';
import MetaTags from 'react-meta-tags';
import ProductCard from '../common/product-card';
import { BASH_URL, API_URL } from './../../services/index';
import MediaQuery from 'react-responsive';
import HeaderComponent from '../common/header.component';
import MobileHeader from '../common/headermobile.component';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import {Helmet} from "react-helmet";


export default class Thank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount () {
      
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Refurbished Laptops Noida | Second Hand Laptop | Upto 60%OFF | Lappyy</title>
                    <meta name="description" content='Top deals on refurbished laptops in Noida @ cheapest price in the market beating the second hand laptops and used laptops in quality & performance with 1 Year Warranty. Pay at your doorstep in Delhi NCR ' />
                    <meta property="og:title" content="Refurbished Laptops Noida | Second Hand Laptop | Upto 60%OFF | Lappyy" />
                </MetaTags>
                <MediaQuery maxWidth={1224}>
                    <MobileHeader />
                </MediaQuery>
                <MediaQuery minWidth={1224}>
                    <HeaderComponent />
                </MediaQuery>                

                <div class="jumbotron text-xs-center">
                    <h1 class="display-3 pt-5">Thank You!</h1>
                    <p class="lead"><strong>Our Experts will get back to you shortly.</strong></p>
                    <hr />
                    <p class="lead">
                        <a class="btn btn-primary btn-sm" href="/" role="button">Continue to homepage</a>
                    </p>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}
