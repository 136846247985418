import React from 'react';
import axios from 'axios';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { BASH_URL, API_URL } from './../../services/index';
import Logo from './../../assest/img/logo/logo.png';

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
};

export default class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: [],
            searchResult: [],
            open: false,
        }
    }

    async searchResponse(event) {
        await axios.get(API_URL + `header/search?search=${event.target.value}`)
            .then(response => {
                this.setState({
                    'searchResult': response.data
                })
            })
            .catch(error => console.log(error))
    }
 
    async getCategory() {

        axios.get(API_URL + 'home/header/category')
            .then(response => {
                this.setState({
                    category: response.data
                })
            })
            .catch(error => console.log(error))
    }


    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    // async getPricefilter() {
    //     axios.get(`${API_URL}filter/price`)
    //         .then(response => {
    //             this.setState({
    //                 'pricefilter': response.data
    //             })
    //         })
    //         .catch(error => console.log(error))
    // }

    componentDidMount() {
        this.getCategory()
    }
    render() {
        const { open } = this.state;
        return (
            <div>

                {/* Bottom Bar Start */}
                <div className="bottom-bar" >
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-2">
                                <div className="logo">
                                    <a href="/">
                                        <img src={Logo} alt="Lappyy" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="category-dropdown">
                                    {this.state.category && this.state.category.map(category => {
                                        return (
                                            <div className="nav-item dropdown">  
                                            {category.sub_category.length > 0 &&
                                            <>
                                                <a style={{ cursor: 'pointer' }} className="nav-link dropdown-toggle category-text" id={category.id} data-toggle="dropdown">{category.category_name}</a>                                                                                               
                                                <div className="dropdown-menu">                                                
                                                    {category.sub_category && category.sub_category.map(subcategory => {
                                                        return (
                                                            <a href={`/category/${subcategory.slug}`} className="dropdown-item">{subcategory.category_names}</a>
                                                        )
                                                    })}
                                                </div>
                                            </>
                                            }
                                            {category.sub_category.length == 0 &&
                                            <a style={{ cursor: 'pointer' }} href={category.slug} className="nav-link category-text">{category.category_name}</a>  
                                            }
                                            </div>                            
                                        )
                                    })}

                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="search">
                                    <input className='search-product' type="text" placeholder="Search" onClick={this.onOpenModal} />
                                    <button><i className="fa fa-search" /></button>
                                </div>
                                <Modal open={open} onClose={this.onCloseModal}>
                                    <input className='modal-search' type="text" placeholder="Search" onChange={(event) => this.searchResponse(event)} />
                                    {/* <button className='modal-search-button'><i className="fa fa-search" /></button> */}
                                    {/* Search Listing */}
                                    {this.state.searchResult && this.state.searchResult.map(result => {
                                        return (
                                            <ul className="product-search">
                                                <li className="list-product"><a href={`/product/${result.slug}`}>
                                                    <div className='product-image-search'>
                                                        <img src={`${result.small_image}`} />
                                                    </div>
                                                    <div className='product-content'>
                                                        <p>{result.product_name}</p>
                                                        {/* <hr />
                                                        <p>{ReactHtmlParser(result.product_description.slice(0, 50))}</p> */}
                                                        <hr />
                                                        <p><del>₹ {result.product_price_actual}</del> <span>₹ {result.product_price_offer}</span></p>
                                                    </div>
                                                </a>
                                                </li>
                                            </ul>
                                        )
                                    })}
                                </Modal>
                            </div>
                            <div className="col-md-1">
                                <div className="users">
                                    <div className="navbar-nav ml-auto">
                                        <div className="nav-item dropdown">
                                            {!localStorage.getItem('token') &&
                                                <>
                                                    <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown"><i className='fa fa-user'></i></a>
                                                    <div className="dropdown-menu">
                                                        <a href="/user/auth" className="dropdown-item">Login/Register</a>
                                                        {/* <a href="#" className="dropdown-item">Register</a> */}
                                                    </div>
                                                </>
                                            }
                                            {localStorage.getItem('token') &&
                                                <>
                                                    <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Hii {localStorage.getItem('user_name')}</a>
                                                    <div className="dropdown-menu">
                                                        {/* <a href="/user/auth" className="dropdown-item">Login/Register</a> */}
                                                        <a href="/user/dashboard" className="dropdown-item">Dashboard</a>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Bottom Bar End */}
            </div>
        )
    }
}