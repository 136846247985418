import React from 'react';
import Slider from "react-slick";
import Arrival2 from './../../assest/img/new-arrival/arrival2.webp'

export default class NewarrivalSlider2 extends React.Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000

        }
        return (
            <div>
                <Slider {...settings}>
                    <div>
                        <div className="header-slider-item">
                            <img style={{ height: '300px' }} src={Arrival2} alt="Slider Image" className='slider-image' />
                        </div>
                    </div>
                </Slider>
            </div>
        );
    }
}