import React from 'react';
import EnquiryForm from './enquiryform';
import { Modal } from 'react-responsive-modal';
import { BASH_URL, API_URL } from './../../services/index';
import Slider from "react-slick";

export default class ProductCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    onOpenModal(product) {
        this.setState({
            open: true,
            product_enquery: product.id
        });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };
    render() {
        const { open } = this.state;
        // const settings = {
        //     dots: true,
        //     infinite: true,
        //     speed: 500,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     // autoplay: true,
        //     autoplaySpeed: 2000

        // }
        //
        return (
            <div>
                <div className="product-item">
                    <div className="product-image">
                        <a href={`/product/${this.props.product.slug}`}>
                        <img src={`${BASH_URL}` + this.props.product.thumbnail_image} alt={this.props.product.product_name} />
                            {/* <Slider {...settings}>
                                <img src={`${BASH_URL}` + this.props.product.thumbnail_image} alt={this.props.product.product_name} />
                                <img src={`${BASH_URL}` + this.props.product.small_image} alt={this.props.product.product_name} />
                                <img src={`${BASH_URL}` + this.props.product.scrach_image} alt={this.props.product.product_name} />
                                <img src={`${BASH_URL}` + this.props.product.full_image} alt={this.props.product.product_name} />
                            </Slider> */}
                        </a>
                    </div>
                    <div className="product-title">
                        <a href={`/product/${this.props.product.slug}`}>{this.props.product.product_name.slice(0, 50)} ...</a>
                        <p>Price : <span> <del>{this.props.product.product_price_actual} ₹</del></span> {this.props.product.product_price_offer}<span>₹</span></p>
                        <a className="btn" onClick={() => this.onOpenModal(this.props.product)}>Enquire Now</a>
                    </div>
                </div>
                <div className='header-search-main'>
                    <Modal className='enquiry-model' open={open} onClose={this.onCloseModal}>
                        <EnquiryForm data={this.state.product_enquery} />
                    </Modal>
                </div>
            </div>

        )
    }
}