import React from 'react';
import axios from 'axios';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { BASH_URL, API_URL } from './../../services/index';
import Logo from './../../assest/img/logo/logo.png'

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
};

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: [],
            searchResult: [],
            settings: [],
            pricefilter: [],
            open: false,
        }
        this.getsubCatogory = this.getsubCatogory.bind(this)
    }

    async searchResponse(event) {
        await axios.get(API_URL + `header/search?search=${event.target.value}`)
            .then(response => {
                this.setState({
                    'searchResult': response.data
                })
            })
            .catch(error => console.log(error))
    }

    async getCategory() {

        axios.get(API_URL + 'home/header/category')
            .then(response => {
                this.setState({
                    category: response.data
                })
            })
            .catch(error => console.log(error))
    }

    async getsubCatogory(id) {
        this.setState({
            subcategory: '',
        })
        console.log(id)
        axios.get(API_URL + `home/header/category/${id}`)
            .then(response => {
                console.log(response.data)
                if (response.data.length >= 1) {
                    this.setState({
                        'subcategory': response.data
                    })
                } else {
                    this.setState({
                        'subcategory': []
                    })
                }
            })
            .catch(error => console.log(error))
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };



    async getPricefilter() {
        axios.get(`${API_URL}filter/price`)
            .then(response => {
                this.setState({
                    'pricefilter': response.data
                })
            })
            .catch(error => console.log(error))
    }

    componentDidMount() {
        this.getCategory()
        this.getPricefilter()
    }
    render() {
        const { open } = this.state;
        return (
            <div>
                <div className="nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-md navbar-dark">
                            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                                <span className="navbar-toggler-icon" />
                            </button>
                            <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                                <div className="navbar-nav mr-auto">
                                    <a href="/" className="nav-item nav-link active">Home</a>
                                    {this.state.category && this.state.category.map(category => {
                                        return (
                                            <div className="nav-item dropdown">
                                                {category && category.slug == "price-range" &&
                                                    <div className="dropdown-menu">
                                                        {this.state.pricefilter && this.state.pricefilter.map(subcategory => {
                                                            return (
                                                                <a href={`/category/${subcategory.slug}`} className="dropdown-item">{subcategory.price_minimum}</a>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                                {category && category.slug != "price-range" &&
                                                    <>
                                                        <a style={{ cursor: 'pointer' }} className="nav-link dropdown-toggle" data-toggle="dropdown" onClick={() => this.getsubCatogory(category.id)} >{category.category_names}</a>
                                                        <div className="dropdown-menu">
                                                            {this.state.subcategory && this.state.subcategory.map(subcategory => {
                                                                return (
                                                                    <a href={`/category/${subcategory.slug}`} className="dropdown-item">{subcategory.category_names}</a>
                                                                )
                                                            })}
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        )
                                    })}

                                </div>
                                <div className="navbar-nav ml-auto">
                                    <div className="nav-item dropdown">
                                        {!localStorage.getItem('token') &&
                                            <>
                                                <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">User Account</a>
                                                <div className="dropdown-menu">
                                                    <a href="/user/auth" className="dropdown-item">Login/Register</a>
                                                    {/* <a href="#" className="dropdown-item">Register</a> */}
                                                </div>
                                            </>
                                        }
                                        {localStorage.getItem('token') &&
                                            <>
                                                <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Hii {localStorage.getItem('user_name')}</a>
                                                <div className="dropdown-menu">
                                                    {/* <a href="/user/auth" className="dropdown-item">Login/Register</a> */}
                                                    <a href="/user/dashboard" className="dropdown-item">Dashboard</a>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                {/* Nav Bar End */}
                {/* Bottom Bar Start */}
                <div className="bottom-bar">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-3">
                                <div className="logo">
                                    <a href="/">
                                        <img src={Logo} alt="Logo" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="search">
                                    <input type="text" placeholder="Search" onClick={this.onOpenModal} />
                                    <button><i className="fa fa-search" /></button>
                                </div>
                                <Modal open={open} onClose={this.onCloseModal}>
                                    <input className='modal-search' type="text" placeholder="Search" onChange={(event) => this.searchResponse(event)} />
                                    {/* <button className='modal-search-button'><i className="fa fa-search" /></button> */}
                                    {/* Search Listing */}
                                    {this.state.searchResult && this.state.searchResult.map(result => {
                                        return (
                                            <ul className="product-search">
                                                <li className="list-product"><a href={`/product/${result.slug}`}>
                                                    <div className='product-image-search'>
                                                        <img src={`${result.small_image}`} />
                                                    </div>
                                                    <div className='product-content'>
                                                        <p>{result.product_name}</p>
                                                        {/* <hr />
                                                        <p>{ReactHtmlParser(result.product_description.slice(0, 50))}</p> */}
                                                        <hr />
                                                        <p><del>₹ {result.product_price_actual}</del> <span>₹ {result.product_price_offer}</span></p>
                                                    </div>
                                                </a>
                                                </li>
                                            </ul>
                                        )
                                    })}
                                </Modal>
                            </div>
                            <div className="col-md-3">
                                <div className="user">
                                    <a href="wishlist.html" className="btn wishlist">
                                        <i className="fa fa-heart" />
                                        <span>(0)</span>
                                    </a>
                                    <a href="cart.html" className="btn cart">
                                        <i className="fa fa-shopping-cart" />
                                        <span>(0)</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}