import React from 'react';
import Slider from "react-slick";
import Arrival1 from './../../assest/img/new-arrival/arrival1.webp'
import { Modal } from 'react-responsive-modal';
import EnquiryForm from './../common/enquiryform';

export default class NewarrivalSlider extends React.Component {
    state = {
        open:false
    }

    onOpenModal(product) {
        this.setState({
            open: true,
            product_enquery: product.id
        });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    render() {
        const { open } = this.state;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000
        }

        return (
            <div>
                <Slider {...settings}>
                    <div>
                        <div className="header-slider-item" onClick={() => this.setState({ open: true })}>
                            <img style={{ height: '300px' }} src={Arrival1} alt="Slider Image" className='slider-image' />
                        </div>
                    </div>

                </Slider>
                <Modal className='enquiry-model' open={open} onClose={this.onCloseModal}>
                    <EnquiryForm />
                </Modal>
            </div>
        );
    }
}