import React from 'react'

export default class Index extends React.Component {
    render() {
        return (
            <>
                <h1>This is home page.</h1>
            </>
        )
    }
}