import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from './../../services/index';
import MediaQuery from 'react-responsive';
import HeaderComponent from '../common/header.component';
import MobileHeader from '../common/headermobile.component';
import Footer from '../common/footer';
import ReactHtmlParser from 'react-html-parser';
import MetaTags from 'react-meta-tags';

const AboutUs = () => {
    const [content, setContent] = useState('')
    useEffect(() => {
        AboutContent()
    }, [])

    const AboutContent = () => {
        axios.post(API_URL + 'pages/cms', {
            'page_name': 'about_us'
        })
            .then(res => {
                console.log(res.data.content)
                setContent(res.data)
            })
            .catch(err => console.log(err))
    }

    return (
        <>
            <MetaTags>
                <title>About Us</title>
                <meta name="description" content='About Us' />
            </MetaTags>
            <MediaQuery maxWidth={1224}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1224}>
                <HeaderComponent />
            </MediaQuery>
            <div className='aboutus-main'>
                <div className='top-containt'>
                    <h1>About Us</h1>
                </div>
                <div className='content container'>
                    <div className='row refurb-content homefooter-content'>
                        {/* <FooterHomeContent /> */}
                        {ReactHtmlParser(content.content)}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AboutUs