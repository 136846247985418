import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASH_URL, API_URL } from './../../services/index';
import { Modal } from 'react-responsive-modal';
import Logo from './../../assest/img/logo/logo.png';

const MobileHeader = () => {
  // const [logo, setLogo] = useState('');
  const [category, setCategory] = useState('');
  const [menu, setMenu] = useState(false);
  const [hub, setHub] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchResult, setSearchResult] = useState('');

  useEffect(() => {
    Category()
  }, [])


  const Category = () => {
    axios.get(API_URL + 'home/header/category')
      .then(response => {
        setCategory(response.data)
        console.log(response.data)
      })
      .catch(error => console.log(error))
  }

  // const Setting = () => {
  //   axios.get(API_URL + 'app/settings')
  //     .then(response => {
  //       setLogo(response.data[0].site_logo)
  //       console.log(response.data[0].site_logo)
  //     })
  //     .catch(error => console.log('Setting', error))
  // }

  const conrollCategory = () => {
    if (menu) {
      setMenu(false)
      setHub(false)
    } else {
      setMenu(true)
      setHub(false)
    }
  }

  const controllHub = () => {
    if (hub) {
      setHub(false)
      setMenu(false)
    } else {
      setHub(true)
      setMenu(false)
    }
  }

  const onCloseModal = () => {
    setOpen(false)
  }

  const openModel = () => {
    setOpen(true)
  }

  const searchResponse = (event) => {
    axios.get(API_URL + `header/search?search=${event.target.value}`)
      .then(response => {
        setSearchResult(response.data)
      })
      .catch(error => console.log(error))
  }

  return (
    <>
      {!menu &&
        <div className='mobile-logo'>
          <a className='mobile-link'>
            <a href="/"><img src={Logo} alt="Lappyy" /></a>
            <input type='text' onClick={() => openModel()} />
            <i className='fa fa-search search-phone'></i>
            <a href='tel:+919879412913' className='call-header'><i class="fas fa-phone-alt hea-ph"></i></a>
          </a>
        </div>
      }
      {menu &&
        <>
          <div className='category-view'>
            <h3 style={{ textAlign: 'center' }}>TOP CATEGORY</h3>
            <hr></hr>
            <div className='category-list'>
              <div className='row'>
                {category && category.map(item => {
                  return (
                    <>
                      {item.category_name == "Brand" &&
                        <>
                          {item.sub_category && item.sub_category.map(cat => {
                            return (
                              <>
                                <div className='mobile-subcategory'>
                                  <ul>
                                    <li>
                                      <a href={'/category/' + cat.slug}>
                                        <img src={BASH_URL + cat.category_image} />
                                        <p>{cat.category_names}</p>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </>
                            )
                          })}
                        </>
                      }
                      {item.slug == "processor" &&
                        <>
                          {item.sub_category && item.sub_category.map(cat => {
                            return (
                              <>
                                <div className='mobile-subcategory'>
                                  <ul>
                                    <li>
                                      <a href={'/category/' + cat.slug}>
                                        <img src={BASH_URL + cat.category_image} />
                                        <p>{cat.category_names}</p>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </>
                            )
                          })}
                        </>
                      }
                    </>
                  )
                }
                )}
              </div>
            </div>
            <hr></hr>
            <div className='price-filter'>
              <ul style={{ padding: '0px' }}>
                {category && category.map(item => {
                  return (
                    <>
                      {item.slug == "price-range" &&
                        <>
                          {item.sub_category && item.sub_category.map(cat => {
                            return (
                              <>
                                <li><a href={'/category/' + cat.slug}>{cat.category_names}</a></li>
                              </>
                            )
                          }
                          )}
                        </>
                      }
                    </>
                  )
                })}
              </ul>
            </div>
          </div>
        </>
      }

      {hub &&
        <>
          <div className='our-hub'>
            <hr/>
            <h3 style={{textAlign:'center'}}>OUR LOCATION</h3><hr/>
            <ul>

              <li><a href="/refurbished-laptop-delhi"><i style={{color:'purple', marginRight:'10px'}} class="fas fa-city"></i> Refurbished Laptops In Delhi</a></li>
              <li><a href="/refurbished-laptop-gurgaon"><i style={{color:'purple', marginRight:'10px'}} class="fas fa-city"></i> Refurbished Laptops In Gurgaon</a></li>
              <li><a href="/refurbished-laptop-noida"><i style={{color:'purple', marginRight:'10px'}} class="fas fa-city"></i> Refurbished Laptops In Noida</a></li>

            </ul>
          </div>
        </>
      }
      <div className='mobile-header'>
        <div className='mobile-nav'>
          <ul className='mobile-list'>
            <li><a style={{color:'#fff'}} href='/'><i className='fa fa-home'></i><br />Home</a></li>
            <li onClick={() => conrollCategory()}><i className="fas fa-align-justify"></i><br />Category</li>
            <li onClick={() => controllHub()}><i className="fas fa-street-view"></i><br />Our Hub</li>
            <li><a style={{color:'#fff'}} href='/user/auth'><i className="far fa-user-circle"></i><br />Account</a></li>
          </ul>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal}>
        <input className='modal-search' type="text" placeholder="Search" onChange={(event) => searchResponse(event)} />
        {searchResult && searchResult.map(result => {
          return (
            <ul className="product-search">
              <li className="list-product"><a href={`/product/${result.slug}`}>
                <div className='product-image-search'>
                  <img src={`${result.small_image}`} />
                </div>
                <div className='product-content'>
                  <p>{result.product_name}</p>
                  {/* <hr />
                                                        <p>{ReactHtmlParser(result.product_description.slice(0, 50))}</p> */}
                  <hr />
                  <p><del>₹ {result.product_price_actual}</del> <span>₹ {result.product_price_offer}</span></p>
                </div>
              </a>
              </li>
            </ul>
          )
        })}
      </Modal>

    </>
  )
}

export default MobileHeader;