import React from 'react';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { API_URL } from './../../services/index';

export default class Footer extends React.Component {
    state = {
        getIn_touch: [],
        social_media: [],
        purchase_info: [],
        company_info: [],
    }


    render() {
        return (
            <div>
                <div>
                    <div className='wp'>
                        <a onClick={()=> window.open("https://wa.me/+917607919819?text=Hii Lappyy", "_blank")}><p className='wp-p'><i className="fab fa-whatsapp"></i></p></a>
                    </div>
                </div>
                {/* Footer Start */}
                <div className="footer">
                    <div className="container-fluid">
                        <div className="row mob-foot">
                            <div className="col-lg-3 col-md-6">
                                {/* {ReactHtmlParser(this.state.footer_address)} */}
                                <div className="footer-widget">
                                    <h2>Get in Touch</h2>
                                    <div className="contact-info">
                                        <p><em>B 38, Old DLF COLONY,<br />SUNDAR SINGH MARG,<br />GURGRAM, HR 122001</em></p>
                                        <p><em>info@lappyy.com</em></p>
                                        <p><em>+91 7607919819</em></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-widget">
                                    <h2>{this.state.social_media.title}</h2>
                                    <div className="contact-info">
                                        <div className="social">
                                            {/* <a href><i className="fab fa-twitter" /></a> */}
                                            <a onClick={()=> window.open("https://www.facebook.com/lappyyindia/", "_blank")}><i className="fab fa-facebook-f" /></a>
                                            <a onClick={()=> window.open("https://www.instagram.com/lappyyindia/", "_blank")}><i className="fab fa-instagram" /></a>
                                            <a onClick={()=> window.open("https://www.linkedin.com/in/lappyy/", "_blank")}><i className="fab fa-linkedin-in" /></a>
                                            <a onClick={()=> window.open("https://www.youtube.com/channel/UCv37RmLf0dKzSpdRQsF610g/videos", "_blank")}><i className="fab fa-youtube" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-widget">
                                    <h2>Company Info</h2>
                                    <ul>
                                        <li><a href="/about-us">About Us</a></li>
                                        <li><a href="/privacy">Privacy Policy</a></li>
                                        <li><a href="/term">Terms &amp; Condition</a></li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-widget">
                                    <h2>Our Hub</h2>
                                    <ul>
                                        <li><a href="/refurbished-laptop-delhi">Refurbished Laptops In Delhi</a></li>
                                        <li><a href="/refurbished-laptop-gurgaon">Refurbished Laptops In Gurgaon</a></li>
                                        <li><a href="/refurbished-laptop-noida">Refurbished Laptops In Noida</a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer End */}
                {/* Footer Bottom Start */}
            </div >
        )
    }
}
