import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Home from './../components/static/home';
import Category from './../components/static/category';
import ProductDetail from './../components/static/productdetail';
import Auth from './../components/static/auth';
import Dashboard from './../components/static/userdashboard';
import Index from './../components/static/index';
import AboutUs from './../components/static/aboutus';
import TermCondition from './../components/static/termcondition';
import PrivacyPolicy from './../components/static/privacy';
import Shipping from './../components/static/shipping';
import DelhiPage from './../components/static/delhiPage';
import NoidaPage from './../components/static/noidaPage';
import GurgaonPage from './../components/static/gurgaonPage';
import Blog from './../components/static/blog';
import Thank from './../components/static/thank';
// import Audio from './../assest/audio/intro.mp3'
import SSL from './../components/static/ssl';


export default class Routes extends React.Component {
    // componentDidMount() {
    //     document.getElementById("backgroundMusic").play();
    // }
    render() {
        return (
            <React.Fragment>
                {/* <audio id="backgroundMusic">
                <source src={Audio} type="audio/mpeg" />
                </audio> */}
                <Router>
                    <Switch>
                        <Route exact path='/' component={Home} />
                        <Route exact path='/refurbished-laptop-delhi' component={DelhiPage} />
                        <Route exact path='/refurbished-laptop-noida' component={NoidaPage} />
                        <Route exact path='/refurbished-laptop-gurgaon' component={GurgaonPage} />
                        <Route exact path='/category/:slug' component={Category} />
                        <Route exact path='/product/:slug' component={ProductDetail} />
                        <Route exact path='/blog/:slug' component={Blog} />
                        <Route exact path='/user/dashboard' component={Dashboard} />
                        <Route exact path='/about-us' component={AboutUs} />
                        <Route exact path='/term' component={TermCondition} />
                        <Route exact path='/privacy' component={PrivacyPolicy} />
                        <Route exact path='/shipping' component={Shipping} />
                        <Route exact path='/thanks' component={Thank} />
                        <Route exact path='/.well-known/pki-validation/6BED77E6C74168243B942370FBE6EA19.txt' component={SSL} />
                        {!localStorage.getItem('token') &&
                            <Route exact path='/user/auth' component={Auth} />
                        }
                        {localStorage.getItem('token') &&
                            <Redirect to='/user/dashboard' />
                        }
                    </Switch>
                </Router>
            </React.Fragment>
        )
    }
}