import React from 'react';
import Banner from '../common/banner';
import NewarrivalSlider from '../common/arrivel';
import NewarrivalSlider2 from '../common/arrivel2';
import Footer from '../common/footer';
import axios from 'axios';
import ProductCard from '../common/product-card';
import { API_URL, BASH_URL } from './../../services/index';
import MediaQuery from 'react-responsive';
import HeaderComponent from '../common/header.component';
import MobileHeader from '../common/headermobile.component';
import './../../assest/css/mobile.css';
import CategoryApple from './../../assest/img/top-category/apple.webp';
import CategoryLenovo from './../../assest/img/top-category/lenovo.webp';
import CategoryDell from './../../assest/img/top-category/dell.webp';
import CategoryHP from './../../assest/img/top-category/hp.webp';
import CategoryLast from './../../assest/img/top-category/last.webp';
import FooterHomeContent from './../content/home-footer';
import MetaTags from 'react-meta-tags';
import ReactHtmlParser from 'react-html-parser';
import { Modal } from 'react-responsive-modal';
import EnquiryForm from './../common/enquiryform';

export default class Home extends React.Component {
    constructor(props) {
        super();
        this.state = {
            'card_images': [],
            'featured_product': [],
            'recent_product': [],
            'bestshell_product': [],
            'testimonials': [],
            'product_enquery': '',
            'footerContent': [],
            'list_slider': [],
            'blog': [],
            open:false
        }
    }

    onOpenModal(product) {
        this.setState({
            open: true,
            product_enquery: product.id
        });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    async getBlog() {
        axios.get(API_URL + 'home/blog')
            .then(res => this.setState({ 'blog': res.data }))
            .catch(err => console.log(err))
    }

    async getHomeProduct() {
        await axios.get(API_URL + 'home/products')
            .then(response => {
                this.setState({
                    'featured_product': response.data['feature_product'],
                    'recent_product': response.data['recent_product'],
                    'bestshell_product': response.data['best_seller']
                })
            })
    }

    async loadModel(){
        setTimeout(() => {     
            this.setState({
                open: true
            })
          }, 5000);
        
    }

    componentDidMount() {
        this.getHomeProduct();
        this.getBlog()
        this.loadModel()
    }
    render() {
        const { open } = this.state;
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Refurbished Laptop India | Second Hand Laptop | 60% OFF | Lappyy</title>
                    <meta name="description" content='Best deals on refurbished laptops in India with lowest price in the market beating the second hand laptops and used laptops in quality with high-performance. Get Free Shipping in India with 1 Year Warranty.' />
                    <meta property="og:title" content="Lappyy-Refurbished Laptop India | Second Hand Laptop | Upto 60%OFF | Lappyy" />
                </MetaTags>
                <div>
                    <MediaQuery maxWidth={1224}>
                        <MobileHeader />
                    </MediaQuery>
                    <MediaQuery minWidth={1224}>
                        <HeaderComponent />
                    </MediaQuery>

                    {/* Main Slider Start */}
                    <div className="header">
                        <div className="container-fluids">
                            <div className="rows">
                                {/* <div className="col-md-12"> */}
                                <div>
                                    <div className="header-slider normal-slider">
                                        <Banner />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    {/* Feature End*/}
                    {/* Category Start*/}
                    <div className="category product">
                        <div className="container-fluid">
                            <div className="section-header">
                                <h3>Top Category</h3>
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <div style={{ float: 'left', paddingLeft: '0px' }} className="col-md-6 mobile-card">
                                        <div className="category-item ch-400">
                                            <a href='/category/apple'>
                                                <img src={CategoryApple} />
                                            </a>
                                        </div>
                                    </div>
                                    <div style={{ float: 'left', paddingLeft: '0px' }} className="col-md-6 mobile-card">
                                        <div className="category-item ch-400">
                                            <a href='/category/lenovo'>
                                                <img src={CategoryLenovo} />
                                            </a>
                                        </div>
                                    </div>
                                    <div style={{ float: 'left', paddingLeft: '0px' }} className="col-md-6 mobile-card">
                                        <div className="category-item ch-400">
                                            <a href='/category/dell'>
                                                <img src={CategoryDell} />
                                            </a>
                                        </div>
                                    </div>
                                    <div style={{ float: 'left', paddingLeft: '0px' }} className="col-md-6 mobile-card">
                                        <div className="category-item ch-400">
                                            <a href='/category/hp'>
                                                <img src={CategoryHP} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <MediaQuery maxWidth={1224}>
                                        <div style={{ float: 'left', height: '100%' }}>
                                            <div className="category-lastslider">
                                                <a onClick={() => this.setState({ open: true })}>
                                                    <img className='category-lastslider-img' src={CategoryLast} />
                                                </a>
                                            </div>
                                        </div>
                                    </MediaQuery>
                                    <MediaQuery minWidth={1224}>
                                        <div style={{ float: 'left', height: '100%', marginLeft: "-26px" }}>
                                            <div className="category-lastslider">
                                                <a onClick={() => this.setState({ open: true })}>
                                                    <img className='category-lastslider-img' src={CategoryLast} />
                                                </a>
                                            </div>
                                        </div>
                                    </MediaQuery>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Category End*/}

                    {/* Featured Product Start */}
                    <div className="featured-product product">
                        <div className="container-fluid">
                            <div className="section-header">
                                <h3>FEATURED PRODUCT</h3>
                            </div>
                            <div className="row align-items-center product-slider product-slider-4">
                                {this.state.featured_product && this.state.featured_product.slice(0, 4).map(product => {
                                    return (
                                        <div className="col-lg-3">
                                            <ProductCard product={product} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <MediaQuery minWidth={1224}>
                        <NewarrivalSlider data={this.state.list_slider} />
                    </MediaQuery>

                    {/* Featured Product End */}

                    {/* Recent Product Start */}
                    <div className="recent-product product">
                        <div className="container-fluid">
                            <div className="section-header">
                                <h3>Recent Product</h3>
                            </div>
                            <div className="row align-items-center product-slider product-slider-4">
                                {this.state.recent_product && this.state.recent_product.slice(0, 8).map(product => {
                                    return (
                                        <div className='col-lg-3'>
                                            <ProductCard product={product} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {/* Recent Product End */}
                    <MediaQuery minWidth={1224}>
                        <NewarrivalSlider2 data={this.state.list_slider} />
                    </MediaQuery>

                    {/* Newsletter End */}
                    {/* Recent Product Start */}
                    <MediaQuery minWidth={1224}>
                        <div className="recent-product product">
                            <div className="container-fluid">
                                <div className="section-header">
                                    <h3>Best Seller</h3>
                                </div>
                                <div className="row align-items-center product-slider product-slider-4">
                                    {this.state.bestshell_product && this.state.bestshell_product.slice(0, 8).map(product => {
                                        return (
                                            <div className="col-lg-3">
                                                <ProductCard product={product} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </MediaQuery>

                    {/* Blog Section */}
                    <div className='row'>
                        <div className='container product'>
                            <div class="section-header"><h3>Blog</h3></div>
                            {this.state.blog.slice(0, 3).map(item => {
                                return (
                                    <>
                                        <div class="card-shadow">
                                            <a href={'/blog/' + item.slug}>
                                                <img class="card-img-top" src={BASH_URL + item.blog_thumbnil} alt="Card image cap" />
                                                <div class="card-body">
                                                    <h5 class="blog-title">{item.title.slice(0, 30)}...</h5>
                                                    <hr />
                                                    <p class="blog-short-text">{item.short_description.slice(0,120)} </p>
                                                    <a href={'/blog/' + item.slug} class="read-more-btn">Read More</a>
                                                </div>
                                            </a>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <br />
                    {/* end Section */}

                    {/* Recent Product End */}
                    <div className='container product'>
                        <div className="section-header">
                            <h3>The Refurbished Hub</h3>
                        </div>
                        <div className='row refurb-content homefooter-content'>
                            <FooterHomeContent />
                            {/* {ReactHtmlParser(this.state.footerContent.content)} */}
                        </div>
                    </div>

                    <Footer />
                </div>
                <Modal className='enquiry-model' open={open} onClose={this.onCloseModal}>
                    <EnquiryForm />
                </Modal>
            </React.Fragment>
        )
    }
}