import React from 'react';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import ReactHtmlParser from 'react-html-parser';
import { API_URL, BASH_URL } from './../../services/index';
import Footer from '../common/footer';
import HeaderComponent from '../common/header.component';
import MobileHeader from '../common/headermobile.component';
import MediaQuery from 'react-responsive';
import './../../assest/css/style.css'

export default class Blog extends React.Component {
    state = {
        blog: [],
        suggestBlog: []
    }
    getBlog() {
        axios.get(API_URL + 'blog/' + this.props.match.params.slug)
            .then(res => {
                this.setState({
                    blog: res.data
                })
            })
            .catch(err => console.log(err))
    }

    getRecomend(){
        axios.get(API_URL + 'home/blog')
            .then(res => {
                this.setState({
                    suggestBlog: res.data
                })
            })
            .catch(err => console.log(err))
    }

    componentDidMount() {
        this.getBlog()
        this.getRecomend()
    }
    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Refurbished Laptop India | Second Hand Laptop | 60% OFF | Lappyy</title>
                    <meta name="description" content='Best deals on refurbished laptops in India with lowest price in the market beating the second hand laptops and used laptops in quality with high-performance. Get Free Shipping in India with 1 Year Warranty.' />
                    <meta property="og:title" content="Lappyy-Refurbished Laptop India | Second Hand Laptop | Upto 60%OFF | Lappyy" />
                </MetaTags>
                <div>
                    <MediaQuery maxWidth={1224}>
                        <MobileHeader />
                    </MediaQuery>
                    <MediaQuery minWidth={1224}>
                        <HeaderComponent />
                    </MediaQuery>
                    <div className='blog-main row'>
                        <div className='col-lg-8 blog-block1 ml-5'>
                            <img style={{width:'100%'}} src={BASH_URL + this.state.blog.blog_main_pic} />
                            <br />
                            <h1 className='blog-maintitle'>{this.state.blog.title}</h1>
                            <hr />
                            <div className='blog-content'>
                                {ReactHtmlParser(this.state.blog.blog_description)}
                            </div>
                        </div>
                        <div className='blog-block2'>
                            <h2>Latest Blog</h2>
                            <hr />
                            {this.state.suggestBlog && this.state.suggestBlog.map(item=>{
                                return(
                                    <>
                                     <p><a className="blog-link" style={{color:'#fff'}} href={`/blog/${item.slug}`}>{item.title}</a></p>
                                     <hr />
                                    </>
                                )
                            })
                        } 
                        </div>                        
                    </div>
                    <Footer />
                </div>

            </React.Fragment>

        )
    }
}